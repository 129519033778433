import React, { memo, useEffect } from "react";
import {Layout} from 'antd';
import {isMobile, isTablet} from "react-device-detect";
import {footerText} from "util/config";
import InfoView from 'components/InfoView/mainAppInfo'
import App from "../../Views/MainApp/";
import {useSelector} from "react-redux";
import {useRouteMatch} from "react-router-dom";
import Sidebar from 'components/LayoutComponents/Sidebar';
import NoHeaderNotification from "components/LayoutComponents/Topbar/NoHeaderNotification/index";
import HorizontalDefault from "components/LayoutComponents/Topbar/HorizontalDefault/index";
import HorizontalDark from "components/LayoutComponents/Topbar/HorizontalDark/index";
import InsideHeader from "components/LayoutComponents/Topbar/InsideHeader/index";
import AboveHeader from "components/LayoutComponents/Topbar/AboveHeader/index";
import BelowHeader from "components/LayoutComponents/Topbar/BelowHeader/index";
import Topbar from "components/LayoutComponents/Topbar/index";
import HeaderCustom from "components/LayoutComponents/Topbar/BootStrapHeader";

import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR
} from "constants/ThemeSetting";

const {Content, Footer} = Layout;

const MainApp = (props) => {

  const setupWallet = useSelector(({wallet}) => wallet.walletSetup);
  const navStyle = useSelector(({settings}) => settings.navStyle);
  const match = useRouteMatch();
  let accounSetUpComplete = setupWallet === true || setupWallet === 'true' || setupWallet === '1';
  const heightDivider = isMobile ?  window.innerWidth === 768 ? '150px' :'80px' : '150px';
  let H = props.location.pathname !== '/home'? "auto": "100%";
  let headerBg = props.location.pathname !== '/home'? "#FFF": "#3D2071";

  const getContainerClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DARK_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-container-wrap";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-container-wrap";
      default:
        return '';
    }
  };
  const getNavStyles = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL :
        return <HorizontalDefault/>;
      case NAV_STYLE_DARK_HORIZONTAL :
        return <HorizontalDark/>;
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL :
        return <InsideHeader/>;
      case NAV_STYLE_ABOVE_HEADER :
        return <AboveHeader/>;
      case NAV_STYLE_BELOW_HEADER :
        return <BelowHeader/>;
      case NAV_STYLE_FIXED :
        return <Topbar/>;
      case NAV_STYLE_DRAWER :
        return <Topbar/>;
      case NAV_STYLE_MINI_SIDEBAR :
        return <Topbar/>;
      case NAV_STYLE_NO_HEADER_MINI_SIDEBAR :
        return <NoHeaderNotification/>;
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR :
        return <NoHeaderNotification/>;
      default :
        return null;
    }
  };

  // useEffect(() => {
  //   if(!accounSetUpComplete){
  //       /* eslint-disable react-hooks/exhaustive-deps */
  //      props.history.push(`${match.url}/setup-account`);
  //   }       
  // },[setupWallet])

  return (
    // <Layout style={{height: 'auto'}} className="gx-applayout">
    //   <InfoView/>
    //   <Sidebar/>
    //   {/* {accounSetUpComplete  && <HeaderCustom user={props.user} token={props.token} bg={headerBg} heightDivider={heightDivider}/>} */}
    //   <Layout style={{minHeight: `${window.innerHeight}px`}}>
    //     {/* {accounSetUpComplete  && getNavStyles(navStyle)} */}
    //     <Content style={{height: 'auto'}} className={`gx-layout-content ${getContainerClass(navStyle)} `}>
    //       <App {...props} match={match}/>
    //     </Content>
    //   </Layout>
    //   <Footer>
    //     <div className="gx-layout-footer-content">
    //       {footerText}
    //     </div>
    //   </Footer>
    // </Layout>
    <>

    <Layout className="gx-app-layout">
      <InfoView/>
      <Sidebar/>
      <Layout>
        {getNavStyles(navStyle)}
        <Content className={`gx-layout-content ${getContainerClass(navStyle)} `}>
          <App {...props} match={match}/>
        </Content>
      </Layout>
    </Layout>
    {/* <br/>
      <br/>
        <Footer>
            <div className="gx-layout-footer-content">
              {footerText}
            </div>
        </Footer> */}
    </>
  )
};
export default memo(MainApp);

