import React, {memo, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import URLSearchParams from 'url-search-params'
  import {Redirect, Route, Switch, useHistory, useLocation} from "react-router-dom";
import { ConfigProvider } from 'antd';
import {IntlProvider} from "react-intl";
import ReactGA from 'react-ga';

import AppLocale from "lngProvider";
import MainAppLayout from "./Layouts/MainApp/";
import AuthLayout from "./Layouts/Auth/";
import DefaultLayout from "./Layouts/Default/";
import {setInitUrl, userSignOut, refreshToken} from "appRedux/actions/Auth";
import InfoView from 'components/InfoView/'
import Notfound from 'components/NotFound'
import Scroller from 'util/scroller';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH, DEFAULT_PREFIX_PATH } from 'config/AppConfig'
import {expireJwt} from "util/jwtExpire";

const RestrictedRoute = ({component: Component, location, token, ...rest}) =>
  <Route
    {...rest}
    render={props =>
      token
        ? <Component {...rest} token={token} {...props} />
          : <Redirect
            to={{
              pathname: AUTH_PREFIX_PATH,
              state: {from: location}
            }}
          />}
  />;

let refreshInterval;
const App = (props) => {

  const dispatch = useDispatch();
  const locale = useSelector(({settings}) => settings.locale);
  const token = useSelector(({auth}) => auth.token);
  const authUser = useSelector(({auth}) => auth.authUser);
  const initURL = useSelector(({auth}) => auth.initURL);

  const location = useLocation();
  const { pathname } = useLocation();
  const history = useHistory();
  //const match = useRouteMatch();
  const tokenRefresh = () => dispatch(refreshToken())
  const Logout = () => dispatch(userSignOut())

  const useQuery = () =>  new URLSearchParams(useLocation().search);
  const query = useQuery();
  const ref = query.get("ref");
  if(ref){         
    localStorage.setItem('ref_code', ref);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    // localStorage.clear();
    let link = document.createElement('link');
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.href = "/css/style.css";
    link.className = 'gx-style';
    document.body.appendChild(link);

    document.body.classList.remove('boxed-layout');
    document.body.classList.remove('framed-layout');
    document.body.classList.add('full-layout');

    document.body.classList.add('full-scroll');
    document.body.classList.remove('horizontal-layout');
  },[]);

  useEffect(() => {
    if (initURL === '') {
      dispatch(setInitUrl(location.pathname));
    }

    Scroller();
    ReactGA.initialize('G-JFTL6KGZ03');
    ReactGA.pageview(window.location.pathname + window.location.search);

  }, [dispatch, initURL, location.pathname, location.search]);

  useEffect(() => {
    if(token !== null){
      if(!refreshInterval){
        /* eslint-disable react-hooks/exhaustive-deps */
        refreshInterval = setInterval(tokenRefresh, 40000)
      }

    }else{ 
      if(refreshInterval){
        clearInterval(refreshInterval);
      }
    }
  },[token])

  useEffect(() => {

    //Logout();

    /* eslint-disable react-hooks/exhaustive-deps */
    expireJwt(history, token !== null, Logout); 

    if (location.pathname === '/') {
      if (token === null) {
        history.push(AUTH_PREFIX_PATH);
      } else if (initURL === '' || initURL === '/' || (initURL === AUTH_PREFIX_PATH && token !== null) ) {
        history.push(APP_PREFIX_PATH);
      } else {
        history.push(initURL);
      }
    }
  }, [token, initURL, location, history]);

  const currentAppLocale = AppLocale[locale.locale];

  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}>
          <InfoView/>
        <Switch>
          <Route exact path="/">
            <Redirect to={APP_PREFIX_PATH} />
          </Route>
          <Route path={AUTH_PREFIX_PATH}>
            <AuthLayout />
          </Route>
          {/* <Route path={DEFAULT_PREFIX_PATH}>
            <DefaultLayout user={authUser} token={token} {...props} history={history} location={location}/>
          </Route> */}
          <RestrictedRoute 
            path={APP_PREFIX_PATH} 
            token={token}
            user={authUser} 
            location={location}
            {...props} 
            history={history} 
            location={location}
            component={MainAppLayout}
            // component={<h1>Default Layout</h1>}
            />
          <Route path="*">
            <Notfound/>
          </Route>
        </Switch>
      </IntlProvider>
    </ConfigProvider>
  )
};

export default memo(App);
