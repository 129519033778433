import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {Col, Row} from 'antd';

import {onNavStyleChange, toggleCollapsedSideNav} from "appRedux/actions/Setting";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LITE
} from "../../../constants/ThemeSetting";
import Logo from "assets/images/beengLogo-removebg-preview.png";
import { DEFAULT_PREFIX_PATH } from 'config/AppConfig';


const SidebarLogo = () => {
  const dispatch = useDispatch();
  const {width, themeType} = useSelector(({settings}) => settings);
  const { navCollapsed} = useSelector(({common}) => common);
  let navStyle = useSelector(({settings}) => settings.navStyle);
  if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
    navStyle = NAV_STYLE_DRAWER;
  }
  return (
    <div className="gx-layout-sider-header" style={{alignItems:"center"}}>

      {(navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) ? <div className="gx-linebar">

        <i
          className={`gx-icon-btn icon icon-${navStyle === NAV_STYLE_MINI_SIDEBAR ? 'menu-unfold' : 'menu-fold'} ${themeType !== THEME_TYPE_LITE ? 'gx-text-white' : ''}`}
          onClick={() => {
            if (navStyle === NAV_STYLE_DRAWER) {
              dispatch(toggleCollapsedSideNav(!navCollapsed));
            } else if (navStyle === NAV_STYLE_FIXED) {
              dispatch(onNavStyleChange(NAV_STYLE_MINI_SIDEBAR))
            } else if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
              dispatch(toggleCollapsedSideNav(!navCollapsed));
            } else {
              dispatch(onNavStyleChange(NAV_STYLE_FIXED))
            }
          }}
        />
      </div> : null}
      {/* <Row justify="center">
        <Col xl={11} lg={11} md={11} xs={11} > */}
          <Link to={DEFAULT_PREFIX_PATH} className="gx-site-logo">
            {navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR && width >= TAB_SIZE ?
              <img alt="" src={Logo}/> :
              themeType === THEME_TYPE_LITE ?
                <img alt="" src={Logo}/> :
                <img alt="" src={Logo}/>}

          </Link>
        {/* </Col>
      </Row> */}
    </div>
  );
};

export default SidebarLogo;
