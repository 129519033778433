import React from "react";
import { useDispatch } from "react-redux";
import { Popover, Avatar, Badge } from "antd";
import { userSignOut } from "../../../appRedux/actions/Auth";

const UserProfile = () => {
  const dispatch = useDispatch();

  const { user } = JSON.parse(localStorage.getItem("user") || "{}");

  const userMenuOptions = (
    <ul className="gx-user-popover">
      {/* <li>My Account</li>
      <li>Connections</li> */}
      <li onClick={() => dispatch(userSignOut())}>Logout</li>
    </ul>
  );

  return (
    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Popover
        placement="bottomRight"
        content={userMenuOptions}
        trigger="click"
      >
        {/* className="gx-size-40 gx-pointer gx-mr-3" (avatar class property)*/}
        <Badge  status="success" dot>
          <Avatar
            src={user.picture || ""}
            alt=""
          />
        </Badge>
        <span className="gx-avatar-name">
          {user.username.slice(0, 19) || ""}
          <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
        </span>
      </Popover>
    </div>
  );
};

export default UserProfile;
