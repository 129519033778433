import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {toggleCollapsedSideNav} from "appRedux/actions/Setting";
import IntlMessages from "util/IntlMessages";
import {Link} from "react-router-dom";
import { DEFAULT_PREFIX_PATH } from 'config/AppConfig'

const NoHeaderNotification = () => {

  const dispatch = useDispatch();
  const { navCollapsed} = useSelector(({common}) => common);

  return (
    <div className="gx-no-header-horizontal" style={{background: '#fff'}}>
      <div className="gx-d-block gx-d-lg-none gx-linebar gx-mr-xs-3">
        <i className="gx-icon-btn icon icon-menu"
           onClick={() => {
             dispatch(toggleCollapsedSideNav(!navCollapsed));
           }}
        />
      </div>
      <Link to={`${DEFAULT_PREFIX_PATH}/leaderboard`}>
      <h2 style={{fontWeight: 'bold', color: '#038fde'}}>Administrator</h2>
      </Link>
    </div>
  )
};

export default NoHeaderNotification;
