import React from "react";
import { useSelector } from "react-redux";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
//import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../../constants/ThemeSetting";
import IntlMessages from "../../../util/IntlMessages";
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH, DEFAULT_PREFIX_PATH } from 'config/AppConfig'

const SidebarContent = () => {
  let { navStyle, themeType } = useSelector(({ settings }) => settings);
  let { pathname } = useSelector(({ common }) => common);
  const user = useSelector(({auth}) => auth.authUser);

  //alert(JSON.stringify(user.user.admin));

  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];

  return (
    <>
      <SidebarLogo />
      <div className="gx-sidebar-content">
        <div
          className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
        >
          <UserProfile />
          {/* <AppsNavigation/> */}
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
          >
            <Menu.Item key="home">
              <Link to={`${APP_PREFIX_PATH}`}>
                <i className="icon icon-home" />
                <span>
                  {/* <IntlMessages id="sidebar.dashboard" /> */}
                  Feeds
                </span>
              </Link>
            </Menu.Item>

            {/* <Menu.Item key="dashboard">
              <Link to="/app/transaction">
                <i className="icon icon-widgets" />
                <span>
                  Transaction
                </span>
              </Link>
            </Menu.Item> */}

            <Menu.Item key="dashboard">
              <Link to={`${APP_PREFIX_PATH}/add-feed`}>
                <i className="icon icon-alert" />
                <span>
                  addFeed
                </span>
              </Link>
            </Menu.Item>

            {/* <Menu.Item key="leaderboard">
              <Link to={`${DEFAULT_PREFIX_PATH}/leaderboard`}>
                <i className="icon icon-alert" />
                <span>
                  <IntlMessages id="sidebar.leaderboard" />
                </span>
              </Link>
            </Menu.Item> */}

            {
              user.user.admin && user.user.admin === 'MANAGER' && <Menu.Item key="manage">
              <Link to={`/app/add-candidate`}>
                <i className="icon icon-user" />
                <span>
                  Add Post
                </span>
              </Link>
            </Menu.Item>
            }

            <Menu.Item key="settings">
              <Link to="/app/settings">
                <i className="icon icon-setting" />
                <span>
                  <IntlMessages id="sidebar.settings" />
                </span>
              </Link>
            </Menu.Item>
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};

export default SidebarContent;
