import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    FEED_DATA_STACK,
    LATEST_FEEDS,
    SEARCHED_FEEDS,
    CATEGORY_FEEDS,
    FEED_INFO,
    FEEDS,
    REACTED,
    SHOW_MESSAGE,
    UPDATE_INFO
  } from "constants/ActionTypes";
  import axios from 'util/Api'

  export const callHomeDataStack = () => {
    return (dispatch) => {
      dispatch({type: FETCH_START});
      axios.get('feed/home-data-stack').then(({data}) => {
        if (data.success) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: FEED_DATA_STACK, payload: data.data});
  
        } else {
          //console.log("payload: data.error", data.message);
          dispatch({type: FETCH_ERROR, payload: data.data.message});
        }
      }).catch(error => {
        //console.log(error.response);
        dispatch({type: FETCH_ERROR, payload: 'Check Your internet and refresh page'});
      });
    }
  };

  export const getLatestFeeds = () => {
    return (dispatch) => {
      dispatch({type: FETCH_START});
      axios.get('feed/all').then(({data}) => {
        if (data.success) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: LATEST_FEEDS, payload: data.data});
  
        } else {
          //console.log("payload: data.error", data.message);
          dispatch({type: FETCH_ERROR, payload: data.data.message});
        }
      }).catch(error => {

        //console.log(error.response);
        dispatch({type: FETCH_ERROR, payload: 'Check Your internet and refresh page'});
      });
    }
  };

  export const addFeed = (data) => {
    return (dispatch) => {
      dispatch({type: FETCH_START});
      axios.post('feed/create', data ).then(({data}) => {
        if (data.success) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: SHOW_MESSAGE, payload : data.message});
  
        } else {
          //console.log("payload: data.error", data.message);
          dispatch({type: FETCH_ERROR, payload: data.data.message});
        }
      }).catch(error => {
        console.log(error);
        dispatch({type: FETCH_ERROR, payload: 'Check Your internet and refresh page'});
      });
    }
  };

  export const updateFeed = (data, feedId) => {
    return (dispatch) => {
      dispatch({type: FETCH_START});
      axios.post(`feed/update/${feedId}`, data ).then(({data}) => {
        if (data.success) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: SHOW_MESSAGE, payload : data.message});
  
        } else {
          //console.log("payload: data.error", data.message);
          dispatch({type: FETCH_ERROR, payload: data.data.message});
        }
      }).catch(error => {
        console.log(error);
        dispatch({type: FETCH_ERROR, payload: 'Check Your internet and refresh page'});
      });
    }
  };

  export const deleteFeed = (data) => {
    return (dispatch) => {
      dispatch({type: FETCH_START});
      axios.delete(`feed/delete/${data.id}`, data ).then(({data}) => {
        if (data.success) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: SHOW_MESSAGE, payload : data.message});
  
        } else {
          //console.log("payload: data.error", data.message);
          dispatch({type: FETCH_ERROR, payload: data.data.message});
        }
      }).catch(error => {
        console.log(error);
        dispatch({type: FETCH_ERROR, payload: 'Check Your internet and refresh page'});
      });
    }
  };

  export const addReaction = (data) => {
    return (dispatch) => {
      //dispatch({type: FETCH_START});
      axios.post('feed/add-reaction', data ).then(({data}) => {
        if (data.success) {
          dispatch({type: FETCH_SUCCESS});
          // dispatch({type: REACTED, payload : true});
          dispatch({type: UPDATE_INFO, payload: 'reaction'});
  
        } else {
          //console.log("payload: data.error", data.message);
          dispatch({type: FETCH_ERROR, payload: data.data.message});
        }
      }).catch(error => {
        console.log(error);
        dispatch({type: FETCH_ERROR, payload: 'Check Your internet and refresh page'});
      });
    }
  };

  export const allFeeds = () => {
    return (dispatch) => {
      dispatch({type: FETCH_START});
      axios.get('feed/all').then(({data}) => {
        if (data.success) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: FEEDS, payload: data.data});
  
        } else {
          //console.log("payload: data.error", data.message);
          dispatch({type: FETCH_ERROR, payload: data.data.message});
        }
      }).catch(error => {
        dispatch({type: FETCH_ERROR, payload: 'Check Your internet and refresh page'});
      });
    }
  };

  export const readFeed = (feed) => {
    return {
      type: FEED_INFO,
      payload: feed
    }
  };