import React, { lazy, Suspense, useEffect } from "react";

import { Redirect, Route, Switch } from "react-router-dom";

import CircularProgress from "components/CircularProgress";

const MainApp = ({history, match, location }) => {

    return (
        <Suspense fallback={<CircularProgress />}>
            <Switch>
                <Route 
                    exact 
                    path={`${match.url}/dashboard`} 
                    component={lazy(() => import(`./Dashboard`))} 
                />
                <Route 
                    path={`${match.url}/settings`} 
                    component={lazy(() => import('./settings'))} 
                />
                <Route 
                    path={`${match.url}/transaction`} 
                    component={lazy(() => import('./Transaction'))} 
                />
                <Route 
                    path={`${match.url}/add-feed`} 
                    component={lazy(() => import('./Feed'))} 
                />
                <Redirect
                    to={{
                        pathname: `${match.url}/dashboard`,
                        state: { from: location }
                    }}
                />
            </Switch>
        </Suspense>

    )
}

export default MainApp;